import { Box, Link, Separator } from '@scale/scaleui-radix';
import { useRouter } from 'next/router';

const DATA_FOLDER_PATH =
  'https://github.com/scaleapi/scaleapi/edit/master/packages/scaleui-radix-docs/data';

export function EditPageLink() {
  const router = useRouter();
  const routerSlug = router.query.slug;
  let filePath = `${DATA_FOLDER_PATH}/${router.pathname.replace('/', '')}`;

  if (Array.isArray(routerSlug)) {
    filePath = filePath.replace('[...slug]', routerSlug.join('/'));
  } else if (routerSlug) {
    filePath = filePath.replace('[slug]', routerSlug);
  }

  const editUrl = `${filePath}.mdx`;

  return (
    <Box>
      <Separator my="8" size="2" />
      <Link
        color="gray"
        href={editUrl}
        rel="noopener noreferrer"
        size="2"
        target="_blank"
        title="Edit this page on GitHub."
      >
        Edit this page on GitHub.
      </Link>
    </Box>
  );
}
