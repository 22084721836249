import {
  faFontCase,
  faPlay,
  faWrench,
  faTableLayout,
  faCodePullRequest,
  faPuzzlePiece,
} from '@fortawesome/pro-regular-svg-icons';
import _sortBy from 'lodash.sortby';

export const themesRoutes = [
  {
    title: 'Overview',
    pages: [
      {
        title: 'Getting started',
        slug: 'themes/docs/overview/getting-started',
      },
      { title: 'Theme', slug: 'themes/docs/overview/theme' },
      { title: 'Layout', slug: 'themes/docs/overview/layout' },

      {
        title: 'Spacing 🆕',
        slug: 'themes/docs/overview/spacing',
      },
      {
        title: 'Font size 🆕',
        slug: 'themes/docs/overview/font-size',
      },
      { title: 'Breakpoints', slug: 'themes/docs/theme/breakpoints' },
      {
        title: 'Colors 🆕',
        slug: 'themes/docs/overview/colors',
      },
      {
        title: 'Tailwind CSS integration',
        slug: 'themes/docs/overview/tailwind-css-integration',
      },
      {
        title: 'Migrate from Scale UI',
        slug: 'themes/docs/overview/migrate-from-scaleui',
      },
      { title: 'Changelog', slug: 'themes/docs/overview/changelog' },
      { title: 'Usage 🆕', slug: 'themes/docs/overview/usage' },
      { title: 'Roadmap', slug: 'themes/docs/overview/roadmap' },
    ],
    icon: faPlay,
  },
  // TODO: Review this part later
  // {
  //   label: 'Theme',
  //   pages: [
  //     { title: 'Overview', slug: 'themes/docs/theme/overview' },
  //     { title: 'Color', slug: 'themes/docs/theme/color' },
  //     { title: 'Dark mode', slug: 'themes/docs/theme/dark-mode' },
  //     { title: 'Typography', slug: 'themes/docs/theme/typography' },
  //     { title: 'Spacing', slug: 'themes/docs/theme/spacing' },
  //     { title: 'Breakpoints', slug: 'themes/docs/theme/breakpoints' },
  //     { title: 'Radius', slug: 'themes/docs/theme/radius' },
  //     { title: 'Shadows', slug: 'themes/docs/theme/shadows' },
  //     { title: 'Cursors', slug: 'themes/docs/theme/cursors' },
  //   ],
  // },
  {
    title: 'Layout',
    pages: [
      { title: 'Box', slug: 'themes/docs/components/box' },
      { title: 'Flex', slug: 'themes/docs/components/flex' },
      { title: 'Grid', slug: 'themes/docs/components/grid' },
      { title: 'Container', slug: 'themes/docs/components/container' },
      { title: 'Section', slug: 'themes/docs/components/section' },
    ],
    icon: faTableLayout,
  },
  {
    title: 'Typography',
    pages: _sortBy(
      [
        { title: 'Text', slug: 'themes/docs/components/text' },
        { title: 'Heading', slug: 'themes/docs/components/heading' },
        { title: 'Blockquote', slug: 'themes/docs/components/blockquote' },
        { title: 'Code', slug: 'themes/docs/components/code' },
        // { title: 'Em', slug: 'themes/docs/components/em' },
        { title: 'Kbd', slug: 'themes/docs/components/kbd' },
        { title: 'Link', slug: 'themes/docs/components/link' },
        // { title: 'Quote', slug: 'themes/docs/components/quote' },
        { title: 'Strong', slug: 'themes/docs/components/strong' },
      ],
      'title'
    ),
    icon: faFontCase,
  },
  {
    title: 'Components',
    pages: _sortBy(
      [
        { title: 'Alert Dialog', slug: 'themes/docs/components/alert-dialog' },
        { title: 'Aspect Ratio', slug: 'themes/docs/components/aspect-ratio' },
        { title: 'Avatar', slug: 'themes/docs/components/avatar' },
        { title: 'Autocomplete 🆕 🧪', slug: 'themes/docs/components/autocomplete' },
        { title: 'Badge', slug: 'themes/docs/components/badge' },
        { title: 'Button', slug: 'themes/docs/components/button' },
        { title: 'Callout', slug: 'themes/docs/components/callout' },
        { title: 'Card', slug: 'themes/docs/components/card' },
        { title: 'Checkbox', slug: 'themes/docs/components/checkbox' },
        {
          title: 'Checkbox Group',
          slug: 'themes/docs/components/checkbox-group',
        },
        {
          title: 'Checkbox Cards',
          slug: 'themes/docs/components/checkbox-cards',
        },
        { title: 'Context Menu', slug: 'themes/docs/components/context-menu' },
        { title: 'Data List', slug: 'themes/docs/components/data-list' },
        { title: 'Dialog', slug: 'themes/docs/components/dialog' },
        { title: 'Dropdown Menu', slug: 'themes/docs/components/dropdown-menu' },
        { title: 'Form Field 🆕 🧪', slug: 'themes/docs/components/form-field' },
        { title: 'Hover Card', slug: 'themes/docs/components/hover-card' },
        { title: 'Icon 🆕 🧪', slug: 'themes/docs/components/icon' },
        { title: 'Icon Button', slug: 'themes/docs/components/icon-button' },
        { title: 'Inset', slug: 'themes/docs/components/inset' },
        { title: 'Popover', slug: 'themes/docs/components/popover' },
        { title: 'Progress', slug: 'themes/docs/components/progress' },
        { title: 'Radio', slug: 'themes/docs/components/radio' },
        { title: 'Radio Group', slug: 'themes/docs/components/radio-group' },
        { title: 'Radio Cards', slug: 'themes/docs/components/radio-cards' },
        { title: 'Brand Logo 🆕 🧪', slug: 'themes/docs/components/brand-logo' },
        { title: 'Scroll Area', slug: 'themes/docs/components/scroll-area' },
        {
          title: 'Segmented Control',
          slug: 'themes/docs/components/segmented-control',
        },
        { title: 'Select', slug: 'themes/docs/components/select' },
        { title: 'Separator', slug: 'themes/docs/components/separator' },
        { title: 'Side Navigation 🆕 🧪', slug: 'themes/docs/components/side-navigation' },
        { title: 'Skeleton', slug: 'themes/docs/components/skeleton' },
        { title: 'Slider', slug: 'themes/docs/components/slider' },
        { title: 'Spinner', slug: 'themes/docs/components/spinner' },
        { title: 'Switch', slug: 'themes/docs/components/switch' },
        { title: 'Table 🆕', slug: 'themes/docs/components/table' },
        { title: 'Top Navigation 🆕 🧪', slug: 'themes/docs/components/top-navigation' },
        {
          title: 'Data Table 🆕 🧪',
          slug: 'themes/docs/components/data-table',
        },
        { title: 'Tabs', slug: 'themes/docs/components/tabs' },
        { title: 'Tab Nav', slug: 'themes/docs/components/tab-nav' },
        { title: 'Text Area', slug: 'themes/docs/components/text-area' },
        { title: 'Text Field', slug: 'themes/docs/components/text-field' },
        { title: 'Tooltip', slug: 'themes/docs/components/tooltip' },
      ],
      'title'
    ),
    icon: faPuzzlePiece,
  },
  {
    title: 'Utilities',
    pages: _sortBy(
      [
        { title: 'Portal', slug: 'themes/docs/components/portal' },
        { title: 'Reset', slug: 'themes/docs/components/reset' },
        { title: 'Slot', slug: 'themes/docs/components/slot' },
        {
          title: 'Visually Hidden',
          slug: 'themes/docs/components/visually-hidden',
        },
      ],
      'title'
    ),
    icon: faWrench,
  },
  {
    title: 'Contributing',
    pages: [
      { title: 'Overview', slug: 'themes/docs/contributing/overview' },
      { title: 'VS Code', slug: 'themes/docs/contributing/using-vscode' },
    ],
    icon: faCodePullRequest,
  },
];
export type PageProps = {
  title: string;
  slug: string;
};

export type RouteProps = {
  title: string;
  pages: PageProps[];
};

export const allThemesRoutes = themesRoutes.reduce((acc, curr: RouteProps) => {
  return [...acc, ...curr.pages];
}, []);
