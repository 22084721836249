export function RadixLogo() {
  return (
    <svg
      fill="currentcolor"
      height="24"
      viewBox="0 0 76 24"
      width="76"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M43.9022 20.0061H46.4499C46.2647 19.0375 46.17 18.1161 46.17 17.0058V12.3753C46.17 9.25687 44.3893 7.72127 41.1943 7.72127C38.3003 7.72127 36.3324 9.23324 36.0777 11.8083H38.9254C39.0181 10.698 39.8052 9.96561 41.1017 9.96561C42.4446 9.96561 43.3243 10.6743 43.3243 12.1391V12.7061L39.8052 13.1077C37.4206 13.3912 35.5684 14.3834 35.5684 16.7931C35.5684 18.9666 37.2353 20.2659 39.5274 20.2659C41.4027 20.2659 42.9845 19.4863 43.6401 18.1161C43.6689 18.937 43.9022 20.0061 43.9022 20.0061ZM40.3377 18.1634C39.157 18.1634 38.5087 17.5727 38.5087 16.6278C38.5087 15.3757 39.4579 15.0922 40.7082 14.9268L43.3243 14.6197V15.352C43.3243 17.242 41.8658 18.1634 40.3377 18.1634ZM56.2588 20.0061H59.176V3H56.2125V9.96561C55.6569 8.76075 54.3141 7.72127 52.4851 7.72127C49.3058 7.72127 47.099 10.2963 47.099 14.0054C47.099 17.7381 49.3058 20.2896 52.4851 20.2896C54.2678 20.2896 55.68 19.2973 56.2588 18.0925V20.0061ZM56.282 14.218C56.282 16.5569 55.1938 18.0689 53.3185 18.0689C51.3969 18.0689 50.1856 16.486 50.1856 14.0054C50.1856 11.5485 51.3969 9.94198 53.3185 9.94198C55.1938 9.94198 56.282 11.454 56.282 13.7928V14.218ZM60.9066 5.97304H64.0553V3.01996H60.9066V5.97304ZM60.9992 20.0061H63.9627V8.00476H60.9992V20.0061ZM67.6638 20.0061L70.6041 15.8954L73.5212 20.0061H76.9246L72.3636 13.7219L76.5542 8.00476H73.3823L70.7661 11.7138L68.1731 8.00476H64.7697L69.0066 13.8637L64.4919 20.0061H67.6638Z" />
      <path
        clipRule="evenodd"
        d="M24.9132 20V14.0168H28.7986L32.4513 20H35.7006L31.6894 13.5686C33.5045 12.986 35.0955 11.507 35.0955 9.01961C35.0955 5.7479 32.7994 4 28.9571 4H22V20H24.9132ZM24.9132 6.35294V11.6863H28.821C31.0395 11.6863 32.1599 10.7675 32.1599 9.01961C32.1599 7.27171 30.9395 6.35294 28.621 6.35294H24.9132Z"
        fillRule="evenodd"
      />
      {/* <path d="M7 23C3.13401 23 0 19.6422 0 15.5C0 11.3578 3.13401 8 7 8V23Z" /> */}
      {/* <path d="M7 0H0V7H7V0Z" /> */}
      {/* <path d="M11.5 7C13.433 7 15 5.433 15 3.5C15 1.567 13.433 0 11.5 0C9.56704 0 8 1.567 8 3.5C8 5.433 9.56704 7 11.5 7Z" /> */}
    </svg>
  );
}

export function RadixLogoIcon() {
  return (
    <svg
      fill="currentcolor"
      height="24"
      viewBox="0 0 16 24"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 23C3.13401 23 0 19.6422 0 15.5C0 11.3578 3.13401 8 7 8V23Z" />
      <path d="M7 0H0V7H7V0Z" />
      <path d="M11.5 7C13.433 7 15 5.433 15 3.5C15 1.567 13.433 0 11.5 0C9.56704 0 8 1.567 8 3.5C8 5.433 9.56704 7 11.5 7Z" />
    </svg>
  );
}

export function ScaleUIRadixLogo({ theme = 'light' }) {
  return (
    <svg fill="none" height="28" viewBox="0 0 194 38" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M26.8075 29.8076L15.9999 19L5.19238 8.19246H26.8075V29.8076ZM10.5962 24.4038L5.19238 19H15.9999V29.8076L10.5962 24.4038ZM10.5962 24.4038V29.8076L5.19238 24.4038H10.5962Z"
        fill={theme.toLowerCase().includes('light') ? '#020008' : '#fff'}
        fillRule="evenodd"
      />
      <path
        d="M120.435 9.54552V27.0001H117.802V9.54552H120.435Z"
        fill={theme.toLowerCase().includes('light') ? '#020008' : '#fff'}
        fillOpacity="0.8784"
      />
      <path
        d="M111.431 9.54552H114.073V21.0256C114.073 22.2472 113.786 23.3296 113.212 24.2728C112.638 25.2103 111.832 25.9489 110.792 26.4887C109.752 27.0228 108.533 27.2898 107.136 27.2898C105.743 27.2898 104.528 27.0228 103.488 26.4887C102.448 25.9489 101.641 25.2103 101.067 24.2728C100.493 23.3296 100.207 22.2472 100.207 21.0256V9.54552H102.84V20.8126C102.84 21.6023 103.013 22.304 103.36 22.9177C103.712 23.5313 104.209 24.0143 104.851 24.3665C105.493 24.7131 106.255 24.8864 107.136 24.8864C108.022 24.8864 108.786 24.7131 109.428 24.3665C110.076 24.0143 110.57 23.5313 110.911 22.9177C111.258 22.304 111.431 21.6023 111.431 20.8126V9.54552Z"
        fill={theme.toLowerCase().includes('light') ? '#020008' : '#fff'}
        fillOpacity="0.8784"
      />
      <path
        d="M91.6578 27.2643C90.3681 27.2643 89.2573 26.9887 88.3255 26.4376C87.3993 25.8808 86.6834 25.0995 86.1777 24.0938C85.6777 23.0825 85.4277 21.8978 85.4277 20.5398C85.4277 19.1989 85.6777 18.0171 86.1777 16.9944C86.6834 15.9717 87.388 15.1734 88.2914 14.5995C89.2005 14.0256 90.263 13.7387 91.4789 13.7387C92.2175 13.7387 92.9334 13.8609 93.6266 14.1052C94.3198 14.3495 94.9419 14.733 95.4931 15.2558C96.0442 15.7785 96.4789 16.4575 96.7971 17.2927C97.1152 18.1222 97.2743 19.1308 97.2743 20.3183V21.2217H86.8681V19.3126H94.7772C94.7772 18.6421 94.6408 18.0484 94.3681 17.5313C94.0953 17.0086 93.7118 16.5967 93.2175 16.2955C92.7289 15.9944 92.155 15.8438 91.4959 15.8438C90.78 15.8438 90.155 16.02 89.6209 16.3722C89.0925 16.7188 88.6834 17.1734 88.3936 17.7359C88.1096 18.2927 87.9675 18.8978 87.9675 19.5512V21.0427C87.9675 21.9177 88.1209 22.662 88.4277 23.2756C88.7402 23.8893 89.1749 24.358 89.7317 24.6819C90.2885 25.0001 90.9391 25.1592 91.6834 25.1592C92.1664 25.1592 92.6067 25.091 93.0044 24.9546C93.4022 24.8126 93.7459 24.6023 94.0357 24.3239C94.3255 24.0455 94.5471 23.7018 94.7005 23.2927L97.1124 23.7273C96.9192 24.4376 96.5726 25.0597 96.0726 25.5938C95.5783 26.1222 94.9561 26.5342 94.2061 26.8296C93.4618 27.1194 92.6124 27.2643 91.6578 27.2643Z"
        fill={theme.toLowerCase().includes('light') ? '#020008' : '#fff'}
        fillOpacity="0.8784"
      />
      <path
        d="M82.6879 9.54552V27.0001H80.1396V9.54552H82.6879Z"
        fill={theme.toLowerCase().includes('light') ? '#020008' : '#fff'}
        fillOpacity="0.8784"
      />
      <path
        d="M70.3852 27.2898C69.5557 27.2898 68.8057 27.1364 68.1352 26.8296C67.4648 26.5171 66.9335 26.0654 66.5415 25.4745C66.1551 24.8836 65.9619 24.1592 65.9619 23.3012C65.9619 22.5626 66.104 21.9546 66.3881 21.4773C66.6721 21.0001 67.0557 20.6222 67.5386 20.3438C68.0216 20.0654 68.5613 19.8552 69.1579 19.7131C69.7545 19.5711 70.3625 19.4631 70.9818 19.3893C71.7659 19.2984 72.4023 19.2245 72.8909 19.1677C73.3795 19.1052 73.7346 19.0058 73.9562 18.8694C74.1778 18.733 74.2886 18.5114 74.2886 18.2046V18.145C74.2886 17.4006 74.0784 16.8239 73.6579 16.4148C73.2432 16.0058 72.6238 15.8012 71.8 15.8012C70.942 15.8012 70.2659 15.9915 69.7716 16.3722C69.2829 16.7472 68.9449 17.1648 68.7574 17.6251L66.3625 17.0796C66.6466 16.2842 67.0613 15.6421 67.6068 15.1535C68.1579 14.6592 68.7915 14.3012 69.5074 14.0796C70.2233 13.8523 70.9761 13.7387 71.7659 13.7387C72.2886 13.7387 72.8426 13.8012 73.4278 13.9262C74.0187 14.0455 74.5699 14.2671 75.0812 14.591C75.5983 14.9148 76.0216 15.3779 76.3511 15.9802C76.6807 16.5768 76.8454 17.3523 76.8454 18.3069V27.0001H74.3568V25.2103H74.2545C74.0898 25.5398 73.8426 25.8637 73.5131 26.1819C73.1835 26.5001 72.7602 26.7643 72.2432 26.9745C71.7261 27.1847 71.1068 27.2898 70.3852 27.2898ZM70.9392 25.2444C71.6437 25.2444 72.246 25.1052 72.746 24.8268C73.2517 24.5484 73.6352 24.1847 73.8966 23.7359C74.1636 23.2813 74.2971 22.7955 74.2971 22.2785V20.591C74.2062 20.6819 74.0301 20.7671 73.7687 20.8467C73.5131 20.9205 73.2204 20.9859 72.8909 21.0427C72.5613 21.0938 72.2403 21.1421 71.9278 21.1876C71.6153 21.2273 71.354 21.2614 71.1437 21.2898C70.6494 21.3523 70.1977 21.4575 69.7886 21.6052C69.3852 21.7529 69.0613 21.966 68.817 22.2444C68.5784 22.5171 68.4591 22.8808 68.4591 23.3353C68.4591 23.966 68.692 24.4433 69.1579 24.7671C69.6238 25.0853 70.2176 25.2444 70.9392 25.2444Z"
        fill={theme.toLowerCase().includes('light') ? '#020008' : '#fff'}
        fillOpacity="0.8784"
      />
      <path
        d="M58.5725 27.2643C57.3054 27.2643 56.2145 26.9773 55.2998 26.4035C54.3907 25.8239 53.6918 25.0256 53.2032 24.0086C52.7145 22.9915 52.4702 21.8268 52.4702 20.5143C52.4702 19.1847 52.7202 18.0114 53.2202 16.9944C53.7202 15.9717 54.4248 15.1734 55.3339 14.5995C56.2429 14.0256 57.314 13.7387 58.5469 13.7387C59.5412 13.7387 60.4276 13.9234 61.206 14.2927C61.9844 14.6563 62.6123 15.1677 63.0895 15.8268C63.5725 16.4859 63.8594 17.2558 63.9503 18.1364H61.4702C61.3339 17.5228 61.0214 16.9944 60.5327 16.5512C60.0498 16.108 59.402 15.8864 58.5895 15.8864C57.8793 15.8864 57.2571 16.0739 56.7231 16.4489C56.1946 16.8183 55.7827 17.3467 55.4873 18.0342C55.1918 18.716 55.0441 19.5228 55.0441 20.4546C55.0441 21.4092 55.189 22.233 55.4787 22.9262C55.7685 23.6194 56.1776 24.1563 56.706 24.537C57.2401 24.9177 57.8679 25.108 58.5895 25.108C59.0725 25.108 59.51 25.02 59.902 24.8438C60.2998 24.662 60.6321 24.4035 60.8992 24.0683C61.1719 23.733 61.3623 23.3296 61.4702 22.858H63.9503C63.8594 23.7046 63.5839 24.4603 63.1236 25.1251C62.6634 25.7898 62.0469 26.3126 61.2742 26.6933C60.5071 27.0739 59.6066 27.2643 58.5725 27.2643Z"
        fill={theme.toLowerCase().includes('light') ? '#020008' : '#fff'}
        fillOpacity="0.8784"
      />
      <path
        d="M47.4373 14.1307C47.3464 13.3239 46.9714 12.6989 46.3123 12.2557C45.6532 11.8069 44.8236 11.5825 43.8236 11.5825C43.1077 11.5825 42.4884 11.6961 41.9657 11.9234C41.443 12.145 41.0367 12.4518 40.7469 12.8438C40.4628 13.2302 40.3208 13.6705 40.3208 14.1648C40.3208 14.5796 40.4174 14.9376 40.6106 15.2387C40.8094 15.5398 41.068 15.7927 41.3861 15.9972C41.71 16.1961 42.0566 16.3637 42.4259 16.5001C42.7952 16.6307 43.1503 16.7387 43.4913 16.8239L45.1958 17.2671C45.7526 17.4035 46.3236 17.5881 46.9089 17.8211C47.4941 18.054 48.0367 18.3609 48.5367 18.7415C49.0367 19.1222 49.4401 19.5938 49.7469 20.1563C50.0594 20.7188 50.2157 21.3921 50.2157 22.1762C50.2157 23.1648 49.96 24.0427 49.4486 24.8097C48.943 25.5768 48.2072 26.1819 47.2413 26.6251C46.281 27.0682 45.1191 27.2898 43.7555 27.2898C42.4486 27.2898 41.318 27.0825 40.3634 26.6677C39.4089 26.2529 38.6617 25.6648 38.1219 24.9035C37.5822 24.1364 37.2839 23.2273 37.2271 22.1762H39.8691C39.9202 22.8069 40.1248 23.3325 40.4827 23.7529C40.8464 24.1677 41.3094 24.4773 41.8719 24.6819C42.4401 24.8807 43.0623 24.9802 43.7384 24.9802C44.4827 24.9802 45.1447 24.8637 45.7242 24.6307C46.3094 24.3921 46.7697 24.0626 47.1049 23.6421C47.4401 23.216 47.6077 22.7188 47.6077 22.1506C47.6077 21.6336 47.46 21.2103 47.1646 20.8807C46.8748 20.5512 46.4799 20.2785 45.9799 20.0626C45.4856 19.8467 44.9259 19.6563 44.3009 19.4915L42.2384 18.929C40.8407 18.5484 39.7327 17.9887 38.9146 17.2501C38.1021 16.5114 37.6958 15.5342 37.6958 14.3182C37.6958 13.3126 37.9685 12.4347 38.514 11.6847C39.0594 10.9347 39.7981 10.3523 40.7299 9.93757C41.6617 9.51711 42.7128 9.30688 43.8833 9.30688C45.0651 9.30688 46.1077 9.51427 47.0111 9.92904C47.9202 10.3438 48.6361 10.9148 49.1589 11.6421C49.6816 12.3637 49.9543 13.1932 49.9771 14.1307H47.4373Z"
        fill={theme.toLowerCase().includes('light') ? '#020008' : '#fff'}
        fillOpacity="0.8784"
      />
      <path
        d="M181.418 13.9091L184.554 19.2614L187.691 13.9091H190.009L185.781 20.4545L190.009 27H187.691L184.554 21.9205L181.418 27H179.1L183.259 20.4545L179.1 13.9091H181.418Z"
        fill={theme.toLowerCase().includes('light') ? '#65636D' : '#b5b2bcff'}
      />
      <path
        d="M174.322 27V13.9091H176.334V27H174.322ZM175.345 11.7273C174.953 11.7273 174.615 11.5938 174.331 11.3267C174.052 11.0597 173.913 10.7386 173.913 10.3636C173.913 9.98864 174.052 9.66761 174.331 9.40057C174.615 9.13352 174.953 9 175.345 9C175.737 9 176.072 9.13352 176.351 9.40057C176.635 9.66761 176.777 9.98864 176.777 10.3636C176.777 10.7386 176.635 11.0597 176.351 11.3267C176.072 11.5938 175.737 11.7273 175.345 11.7273Z"
        fill={theme.toLowerCase().includes('light') ? '#65636D' : '#b5b2bcff'}
      />
      <path
        d="M164.459 27.2727C163.368 27.2727 162.405 26.9972 161.57 26.446C160.735 25.8892 160.081 25.1051 159.61 24.0938C159.138 23.0767 158.902 21.875 158.902 20.4886C158.902 19.1136 159.138 17.9205 159.61 16.9091C160.081 15.8977 160.738 15.1165 161.578 14.5653C162.419 14.0142 163.391 13.7386 164.493 13.7386C165.346 13.7386 166.019 13.8807 166.513 14.1648C167.013 14.4432 167.394 14.7614 167.655 15.1193C167.922 15.4716 168.13 15.7614 168.277 15.9886H168.448V9.54546H170.459V27H168.516V24.9886H168.277C168.13 25.2273 167.919 25.5284 167.647 25.892C167.374 26.25 166.985 26.571 166.479 26.8551C165.973 27.1335 165.3 27.2727 164.459 27.2727ZM164.732 25.4659C165.539 25.4659 166.221 25.2557 166.777 24.8352C167.334 24.4091 167.757 23.821 168.047 23.071C168.337 22.3153 168.482 21.4432 168.482 20.4545C168.482 19.4773 168.34 18.6222 168.056 17.8892C167.772 17.1506 167.351 16.5767 166.794 16.1676C166.238 15.7528 165.55 15.5455 164.732 15.5455C163.88 15.5455 163.169 15.7642 162.601 16.2017C162.039 16.6335 161.615 17.2216 161.331 17.9659C161.053 18.7045 160.914 19.5341 160.914 20.4545C160.914 21.3864 161.056 22.233 161.34 22.9943C161.63 23.75 162.056 24.3523 162.618 24.8011C163.186 25.2443 163.891 25.4659 164.732 25.4659Z"
        fill={theme.toLowerCase().includes('light') ? '#65636D' : '#b5b2bcff'}
      />
      <path
        d="M149.945 27.3068C149.115 27.3068 148.363 27.1506 147.686 26.8381C147.01 26.5199 146.473 26.0625 146.076 25.4659C145.678 24.8636 145.479 24.1364 145.479 23.2841C145.479 22.5341 145.627 21.9261 145.922 21.4602C146.218 20.9886 146.613 20.6193 147.107 20.3523C147.601 20.0852 148.147 19.8864 148.743 19.7557C149.345 19.6193 149.951 19.5114 150.559 19.4318C151.354 19.3295 151.999 19.2528 152.493 19.2017C152.993 19.1449 153.357 19.0511 153.584 18.9205C153.817 18.7898 153.934 18.5625 153.934 18.2386V18.1705C153.934 17.3295 153.703 16.6761 153.243 16.2102C152.789 15.7443 152.098 15.5114 151.172 15.5114C150.212 15.5114 149.459 15.7216 148.914 16.142C148.368 16.5625 147.985 17.0114 147.763 17.4886L145.854 16.8068C146.195 16.0114 146.649 15.392 147.218 14.9489C147.792 14.5 148.417 14.1875 149.093 14.0114C149.774 13.8295 150.445 13.7386 151.104 13.7386C151.524 13.7386 152.007 13.7898 152.553 13.892C153.104 13.9886 153.635 14.1903 154.147 14.4972C154.664 14.804 155.093 15.267 155.434 15.8864C155.774 16.5057 155.945 17.3352 155.945 18.375V27H153.934V25.2273H153.831C153.695 25.5114 153.468 25.8153 153.149 26.1392C152.831 26.4631 152.408 26.7386 151.88 26.9659C151.351 27.1932 150.706 27.3068 149.945 27.3068ZM150.252 25.5C151.047 25.5 151.718 25.3437 152.263 25.0312C152.814 24.7187 153.229 24.3153 153.507 23.821C153.792 23.3267 153.934 22.8068 153.934 22.2614V20.4205C153.848 20.5227 153.661 20.6165 153.371 20.7017C153.087 20.7812 152.757 20.8523 152.382 20.9148C152.013 20.9716 151.652 21.0227 151.3 21.0682C150.953 21.108 150.672 21.142 150.456 21.1705C149.934 21.2386 149.445 21.3494 148.99 21.5028C148.542 21.6506 148.178 21.875 147.899 22.1761C147.627 22.4716 147.49 22.875 147.49 23.3864C147.49 24.0852 147.749 24.6136 148.266 24.9716C148.789 25.3239 149.451 25.5 150.252 25.5Z"
        fill={theme.toLowerCase().includes('light') ? '#65636D' : '#b5b2bcff'}
      />
      <path
        d="M131.114 27V9.54546H137.011C138.375 9.54546 139.494 9.77841 140.369 10.2443C141.244 10.7045 141.892 11.3381 142.313 12.1449C142.733 12.9517 142.943 13.8693 142.943 14.8977C142.943 15.9261 142.733 16.8381 142.313 17.6335C141.892 18.429 141.247 19.054 140.378 19.5085C139.509 19.9574 138.398 20.1818 137.046 20.1818H132.273V18.2727H136.977C137.909 18.2727 138.659 18.1364 139.227 17.8636C139.801 17.5909 140.216 17.2045 140.472 16.7045C140.733 16.1989 140.864 15.5966 140.864 14.8977C140.864 14.1989 140.733 13.5881 140.472 13.0653C140.21 12.5426 139.793 12.1392 139.219 11.8551C138.645 11.5653 137.886 11.4205 136.943 11.4205H133.227V27H131.114ZM139.33 19.1591L143.625 27H141.171L136.943 19.1591H139.33Z"
        fill={theme.toLowerCase().includes('light') ? '#65636D' : '#b5b2bcff'}
      />
    </svg>
  );
}
