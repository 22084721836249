import { useRouter } from 'next/router';

export function useCurrentPageSlug() {
  const router = useRouter();
  const routerSlug = router.query.slug;
  const currentPageSlug = router.pathname.substring(1);

  if (Array.isArray(routerSlug)) {
    return currentPageSlug.replace('[...slug]', routerSlug[0]);
  } else if (routerSlug) {
    return currentPageSlug.replace('[slug]', routerSlug);
  }

  return currentPageSlug;
}
