import { Box, ScrollArea } from '@scale/scaleui-radix';

export function SideNav({ children }) {
  return (
    <Box display={{ initial: 'none', md: 'block' }} style={{ width: 250, flexShrink: 0 }}>
      <Box
        bottom="0"
        left="0"
        position="fixed"
        style={{
          zIndex: 1,
          top: 'var(--header-height)',
          overflowX: 'hidden',
          width: 'inherit',
        }}
      >
        <ScrollArea>{children}</ScrollArea>
      </Box>
    </Box>
  );
}
