import { Link } from '@scale/scaleui-radix';
import type { HeaderProps } from './Header';
import { Header } from './Header';

export function ThemesHeader(props: HeaderProps) {
  return (
    <Header
      gitHubLink="https://github.com/scaleapi/scaleapi/tree/master/packages/scaleui-radix"
      {...props}
    >
      <Link color="gray" href="https://www.radix-ui.com/primitives" size="2" target="_blank">
        Radix Primitives
      </Link>
      <Link
        color="gray"
        href="https://fontawesome.com/search?o=r&s=solid%2Cregular"
        size="2"
        target="_blank"
      >
        Font Awesome Icons
      </Link>
    </Header>
  );
}
