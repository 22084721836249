import { useTheme } from 'next-themes';
import Head from 'next/head';

export function Favicon() {
  const { theme } = useTheme();

  return (
    <Head>
      {theme === 'dark' ? (
        <link href="/favicon-white.svg" rel="icon" />
      ) : (
        <link href="/favicon-black.svg" rel="icon" />
      )}
    </Head>
  );
}
