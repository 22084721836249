'use client';
import { faMoonStars, faSunBright } from '@fortawesome/pro-regular-svg-icons';
import { Flex, Select, Tooltip, Icon } from '@scale/scaleui-radix';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';

const data = {
  light: { label: 'Scale Light', icon: <Icon icon={faSunBright} label="Scale Light" /> },
  dark: { label: 'Scale Dark', icon: <Icon icon={faMoonStars} label="Scale Dark" /> },
  duneLight: { label: 'Dune Light', icon: <Icon icon={faSunBright} label="Dune Light" /> },
  duneDark: { label: 'Dune Dark', icon: <Icon icon={faMoonStars} label="Dune Dark" /> },
  contributorsLight: {
    label: 'Contributors Light',
    icon: <Icon icon={faSunBright} label="Contributors Light" />,
  },
  contributorsDark: {
    label: 'Contributors Dark',
    icon: <Icon icon={faMoonStars} label="Contributors Dark" />,
  },
};

export function ThemeToggle() {
  const [mounted, setMounted] = useState(false);
  const { theme = 'light', setTheme } = useTheme();

  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Select.Root onValueChange={setTheme} value={theme}>
      {/* Add a custom color variable that changes between light and dark mode */}
      {theme === 'duneLight' || theme === 'duneDark' ? (
        <style>{` @import '/dune-colors-to-radix-colors.css'; `}</style>
      ) : theme === 'contributorsLight' || theme === 'contributorsDark' ? (
        <style>{` @import '/contributors-colors-to-radix-colors.css'; `}</style>
      ) : null}
      <Tooltip className="radix-themes-custom-fonts" content="Change theme">
        <Select.Trigger>
          <Flex align="center" as="span" gap="2">
            {data[theme]?.icon}
            {data[theme]?.label}
          </Flex>
        </Select.Trigger>
      </Tooltip>
      <Select.Content>
        <Select.Group>
          <Select.Item value="light">Scale Light</Select.Item>
          <Select.Item value="dark">Scale Dark</Select.Item>
        </Select.Group>
        <Select.Separator />
        <Select.Group>
          <Select.Item value="contributorsLight">Contributors Light</Select.Item>
          <Select.Item value="contributorsDark">Contributors Dark</Select.Item>
        </Select.Group>
        <Select.Separator />
        <Select.Group>
          <Select.Item value="duneLight">Dune Light</Select.Item>
          <Select.Item value="duneDark">Dune Dark</Select.Item>
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
}
